/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: '/merchant/dashboard',
    name: 'Dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    slug: 'merchant-dashboard'
  },
  {
    header: 'Delivery',
    i18n: 'Delivery',
    icon: 'PackageIcon',
    items: [
      {
        url: '/merchant/orders/delivery-orders',
        name: 'Delivery Orders',
        i18n: 'Delivery Orders',
        slug: 'shipments',
        icon: 'TruckIcon'
      },
      {
        url: '/merchant/orders/returns',
        name: 'Returns',
        i18n: 'Returns',
        slug: 'resturns',
        icon: 'PackageIcon'
      },
      {
        url: '/merchant/orders/exchanges',
        name: 'Exchanges',
        i18n: 'Exchanges',
        slug: 'exchanges',
        icon: 'RepeatIcon'
      },
      {
        url: '/merchant/orders/cash-collections',
        name: 'Cash Collection',
        i18n: 'Cash Collection',
        slug: 'cash-collection',
        icon: 'DollarSignIcon'
      },
      {
        url: '/merchant/orders/supplies-shop',
        name: 'Supplies Shop',
        i18n: 'Supplies Shop',
        slug: 'merchant-supplies-shop',
        icon: 'ShoppingBagIcon'
      },
      {
        url: '/merchant/action-center',
        name: 'Action Center',
        i18n: 'Action Center',
        slug: 'merchant-action-center',
        icon: 'MousePointerIcon'
      }
    ]
  },
  {
    header: 'Magic',
    i18n: 'Magic',
    icon: 'LayersIcon',
    items: [
      {
        url: '/merchant/orders/incoming-orders',
        name: 'Incoming Orders',
        i18n: 'Incoming Orders',
        slug: 'shipments',
        icon: 'ZapIcon'
      },
      {
        url: '/merchant/magic-links',
        name: 'Magic Links',
        i18n: 'Magic Links',
        slug: 'magic-links',
        icon: 'LinkIcon'
      }
    ]
  },
  {
    header: 'Merchant Profile',
    i18n: 'Merchant Profile',
    icon: 'LayersIcon',
    items: [
      {
        url: '/merchant/financials',
        name: 'Financials',
        i18n: 'Financials',
        slug: 'financials',
        icon: 'DollarSignIcon'
      },
      {
        url: '/merchant/integrations/ecommerce-store',
        name: 'Integrations',
        i18n: 'Integrations',
        slug: 'integrations',
        icon: 'GitPullRequestIcon'
      },
      {
        url: '/merchant/analytics',
        name: 'Analytics',
        i18n: 'Analytics',
        slug: 'analytics',
        icon: 'PieChartIcon'
      }
    ]
  }
]

